

export const projects = [
    {
        id: 1,
        img: "https://i.postimg.cc/d1QRFqv4/23-08-2022-11-09-26-REC.png",
        link: "https://blackjack-53ce4a.netlify.app",
        desc: 'My first ever made project. I did it using plain javascript.',
        descPl: 'Mój pierwszy projekt w życiu. Zrobiony tylko za pomocą javascript.',
    },
    {
        id: 2,
        img: "https://i.postimg.cc/YSRCmFLf/catGame.png",
        link: "https://idyllic-dasik-8c0ebc.netlify.app",
        desc: 'Game made with html canvas and javascript. Use arrows to play.',
        descPl: 'Gra zrobiona przy pomocy html canvas i javascript. Użyj strzałek do grania.',
    },
    {
        id: 3,
        img: "https://i.postimg.cc/qvL0vCFs/ecommerce.png",
        link: "https://ecommerce-911748.netlify.app/",
        desc:  'Frontend for ecommerce website, done using react.',
        descPl: 'Frontend dla strony ecommerce, zrobiony za pomocą react.',
    },
    {
        id: 4,
        img: "https://i.postimg.cc/m2wj8Q61/country-Guide.png",
        link: "https://countryguide.000webhostapp.com/",
        desc: 'Country info app. Done with few different APIs, javascript, php and leaflet.js for map.',
        descPl: 'Aplikacja do sprawdzania informacji o krajach. Zrobiona przy użyciu klku różnych API, javascript, php i leaflet.js do mapy.',
    },
    {
        id: 5,
        img: "https://i.postimg.cc/WpHg6pHK/30-09-2022-10-43-21-REC.png",
        link: "https://weatherapp-a834cf.netlify.app/",
        desc: 'Weather app. Using openweather API and react.',
        descPl: 'Aplikacja pogodowa. Zrobiona przy pomocy openweather API i react.',
    },
    {
        id: 6,
        img: "https://i.postimg.cc/DfJc8Yv1/30-09-2022-10-49-53-REC.png",
        link: "http://lukaszpudlewski.co.uk/",
        desc: 'This website. Done with react and email.js for contact form.',
        descPl: 'Ta strona. Zrobiona z react i email.js dla formularza kontaktowego.',
    },
];